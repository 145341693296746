@import "variables";
@import "bootstrap";

body {
    font-family: $font-family-sans-serif;
}

@media(min-width: 768px) {
  .sidebar {
    margin-top: $backend-header-height !important;
  }
}

.navbar {
  height: $backend-header-height;
}
.navbar-brand {
  padding: 12px;
}
.navbar-brand > img {
  height: 60px;
}

#sortable_in, #sortable_out {
  border: 1px solid #eee;
  width: 142px;
  min-height: 20px;
  list-style-type: none;
  margin: 0;
  padding: 5px 0 0 0;
  float: left;
  margin-right: 10px;
}

#sortable_in li, #sortable_out li {
  margin: 0 5px 5px 5px;
  padding: 5px;
  font-size: 1.2em;
  width: 120px;
}

.image-thumbnail {
  max-width: 150px !important;
}

.image-preview {
  max-width: 300px !important;
}

.connectedSortable {
  width: 100% !important;
  border: 1px dashed #EEE !important;
  min-height: 300px !important;
}

.connectedSortable > li {
  width: 98% !important;
  overflow: hidden !important;
}

body.frontend {
  margin-bottom: 60px;
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 60px;
  background-color: #f5f5f5;
}

th > a {
  color: #000;
}

th > a:hover {
  text-decoration: none;
}

.help-icon {
  padding-top: 20px;
}

.help-icon:hover {
  cursor: pointer;
}

a.no-style {
  color: #333;
}

a.no-style:hover {
  text-decoration: none;
}

.dashboard-header {
  color: #333 !important;
  font-size: 150% !important;
  font-weight: bold;
  padding-top: 30px;
  margin-left: 150px;
}